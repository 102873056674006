var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('PageWrapDefault',{attrs:{"errorMessage":_vm.errorMessage,"successMessage":_vm.successMessage,"showSearch":false,"topActionButtonPrimary":_vm.primaryToolbar,"topActionButtonSecondary":_vm.topButtonSecondaryItems},on:{"errorMessageClosed":function($event){_vm.errorMessage = ''},"toolbarSelectItem":_vm.onToolbarItemSelected}},[(!_vm.resourceNotFound)?[(_vm.$getCurrentUser && _vm.$getCurrentUser.role == 'admin')?_c('TabMenu',{staticClass:"mb-12",attrs:{"menuItems":_vm.viewPageMenuItems,"selectedTabKey":_vm.selectedTabKeyView},on:{"tabClicked":_vm.onTabItemClick}}):_vm._e(),(!_vm.showActivities)?_c('div',[(
          _vm.getCurrentUser &&
            (_vm.getCurrentUser.role === 'admin' ||
              _vm.getCurrentUser.role === 'underwriting') &&
            _vm.editing &&
            !_vm.editing.deleted &&
            !_vm.editing.isLegacy
        )?_c('ChangeRequestStatus',{attrs:{"selected":_vm.getProperty(_vm.editing, 'status')},on:{"changeStatus":_vm.changeStatus}}):_vm._e(),(_vm.pageDataInitialized)?_c('div',{staticClass:"flex flex-col sm:flex-row border-b pb-4 mb-4"},[_c('div',{staticClass:"w-full sm:w-1/2 pr-0 sm:pr-4"},[_c('ViewChangeRequestInsurerDetails',{attrs:{"changeRequestData":_vm.billChangeRequestData}})],1),_c('div',{staticClass:"w-full sm:w-1/2 mt-8 sm:mt-0"},[_c('ViewChangeRequestPolicyDetails',{attrs:{"changeRequestData":_vm.billChangeRequestData}}),(
              _vm.billChangeRequestData.billing &&
                _vm.billChangeRequestData.billing.reason &&
                _vm.billChangeRequestData.billing.agentCertify
            )?_c('div',[_c('div',{staticClass:"mb-2"},[_c('div',{staticClass:"text-lg font-medium"},[_c('span',[_vm._v("Cancellation Change Request Action")])])]),_c('div',{staticClass:"mb-2 flex justify-between pb-4"},[(_vm.billChangeRequestData.billing.reason)?_c('div',[_c('div',{staticClass:"font-medium"},[_vm._v("Cancellation Reason")]),_c('div',[_vm._v(" "+_vm._s(_vm.toTitleCase(_vm.billChangeRequestData.billing.reason))+" ")])]):_vm._e(),(_vm.billChangeRequestData.billing.agentCertify)?_c('div',[_c('div',{staticClass:"font-medium"},[_vm._v("Certified By Agent")]),_c('div',[_vm._v(" "+_vm._s(_vm.convertBooleanToText( _vm.billChangeRequestData.billing.agentCertify ))+" ")])]):_vm._e()]),_c('div',{staticClass:"mb-2 pb-4"},[(_vm.billChangeRequestData.billing.remarks)?_c('div',[_c('div',{staticClass:"font-medium"},[_vm._v("Remarks")]),_c('div',[_vm._v(" "+_vm._s(_vm.billChangeRequestData.billing.remarks)+" ")])]):_vm._e()]),_c('div',{staticClass:"mb-2 flex justify-between pb-4"},[(_vm.billChangeRequestData.billing.other)?_c('div',[_c('div',{staticClass:"font-medium"},[_vm._v("Additional Remarks")]),_c('div',[_vm._v(" "+_vm._s(_vm.billChangeRequestData.billing.other)+" ")])]):_vm._e()])]):_vm._e()],1)]):_vm._e(),(_vm.pageDataInitialized && _vm.billChangeRequestData.attachments)?_c('DisplayAttachment',{attrs:{"attachments":_vm.billChangeRequestData.attachments},on:{"deleteRestoreAttachment":file => _vm.handleDeleteAttachment(file, _vm.billChangeRequestData)}}):_vm._e()],1):_c('ActivitiesView',{attrs:{"queryOverride":_vm.queryOverride},scopedSlots:_vm._u([{key:"default",fn:function({ activity }){return [_c('ActivitiesTimelineItem',{attrs:{"activity":activity}})]}}],null,false,3991501741)})]:_c('ResourceNotFound',{attrs:{"title":"Resource not found.","subtitle":"Sorry, the cancellation change request data you were looking for could not be found.","actionButtonTitle":"Back to cancellation change requests","actionRedirectPath":"/billingchangerequests"}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }